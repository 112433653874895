<template lang="pug">
    #cart-sale-add
        #cart-sale-table-add.row(v-if="Object.keys(sale).length")
            .col-xs-12
                .panel.panel-success(v-show="sale.patient_exists === 1 || sale.patient_exists === 2")
                    .panel-heading
                        i.fa.fa-shopping-cart.mr3
                        span.mr3 {{'carts.type.fullname.SALE'|translate}}
                        span(v-if="Object.keys(sale.patient).length")
                            span {{sale.patient.firstName}} {{sale.patient.lastName}}, id {{sale.patient.id}}
                            span(v-show="sale.patient.loyality.typeId > 0")
                                span.mr3(style="margin-left:3px") - {{'carts.type.fullname.CARD'|translate}}
                                span.mr3 {{sale.patient.loyality.typeTranslate|translate}}
                                span ({{getDiscountCard(sale.patient.loyality.typeId)}}%)
                        span.warn(v-show="!Object.keys(sale.patient).length && sale.patient_exists === 1") {{'carts.type.fullname.NOPATIENT'|translate}}
                        span(v-show="sale.patient_exists === 2") {{'carts.type.fullname.NEWCLIENT'|translate}}

                    .table-responsive
                        table.table.middle
                            thead
                                tr
                                    th #
                                    th {{'carts.PRODUCTID'|translate}}
                                    th {{'carts.NAME'|translate}}
                                    th {{'carts.PRICE'|translate}}
                                    th {{'carts.QUANTITY'|translate}}
                                    th {{'carts.VAT'|translate}}
                                    th {{'carts.VALUE'|translate}}
                                    th {{'carts.DISCOUNT'|translate}}
                                    th {{'carts.AFTERDISCOUNT'|translate}}
                                    th

                            tbody
                                template(v-for="(item, key) in sale.items")
                                    tr(:class="{'tr-stripped': key % 2}")
                                        td {{sale.items.length - key}}
                                        td.text-right
                                            OptiProduct(:product="item.product") {{item.product.id}}
                                        td
                                            OptiProduct(:product="item.product") {{item.product.name}}
                                            .label.label-success.pull-right(v-show="item.highlight") {{'carts.NEW'|translate}}
                                            small(style="display:block") {{item.product.fullName}}
                                        td.text-right {{item.product.price|currency}}
                                        td.col-xs-1.text-right {{item.quantity}}
                                        td.text-right(NOWRAP) {{item.product.vat}}%
                                        td.text-right {{item.product.price * item.quantity|currency}}
                                        td.text-right(NOWRAP)
                                            span.mr3(:class="{'text-expressive bold': !item.mode}") {{item.percentDiscount|number(2)}}%
                                            span(:class="{'text-expressive bold': item.mode}") {{item.discount|currency}}
                                        td.text-right(:class="{'warn': item.product.price * item.quantity - item.discount < 0}"
                                            ) {{item.product.price * item.quantity - item.discount|currency}}
                                        td.text-center(rowspan="2")
                                            a(:href="$state.href('app.catalog.product', {id: item.product.id, departmentId: $uac.getUser().activeDepartmentId})"
                                                :title="'sale.items.table.action.DETAILS'|translate")
                                                i.fa.fa-2x.fa-fw.fa-info-circle

                                    tr(:class="{'tr-stripped': key % 2}")
                                        td.b0(:colspan="3")
                                            .text-warning(v-show="item.product.depository.stock - item.quantity < 0")
                                                i.fa.fa-exclamation-circle.mr3
                                                span {{'carts.notify.error.nostock.LOCAL'|translate}}
                                            .warn(v-show="item.product.price * item.quantity - item.discount < 0")
                                                i.fa.fa-exclamation-circle.mr3
                                                span {{'carts.sale.notify.error.MINUSVALUE'|translate}}
                                        td.b0.text-right.bold
                                            span(v-show="!item.is_coupon") {{'carts.COMMENTS'|translate}}
                                            span(v-show="item.is_coupon") {{'carts.sale.coupons.CODE'|translate}}
                                        td.b0(colspan="5") {{item.comments}}

                                tr(v-show="!sale.items.length")
                                    td.text-center.text-muted(colspan="100")
                                        i.fa.fa-exclamation-circle.mr3
                                        span {{'default.table.EMPTY'|translate}}

                            tfoot
                                tr.align-middle.text-expressive
                                    th(colspan="3")
                                    th.text-right {{'carts.TOTALS'|translate}}
                                    th.text-right {{sale.totals.quantity}}
                                    th
                                    th.text-right {{sale.totals.value|currency}}
                                    th.text-right ({{sale.totals.percentDiscount|number(2)}}%) {{sale.totals.discount|currency}}
                                    th.text-right {{sale.totals.afterDiscount|currency}}
                                    td

            .col-md-offset-4.col-md-8
                form.form-horizontal
                    OptiPayment(
                        :payments="payment"
                        :value="sale.totals.afterDiscount"

                        :required="true"

                        @invalid="i => invalid.payment = i")
                    .form-group
                        label.col-sm-4.control-label.star-required(for="receipt") {{'sale.offstock.table.head.NORECEIPT'|translate}}
                        .col-sm-8
                            OptiReceipt#receipt(
                                :model="form.receipt"

                                :required="true"

                                @invalid="i => invalid.receipt = i")
                    .form-group
                        label.col-sm-4.control-label(for="marketing_action_id") {{'sale.PROMOTION'|translate}}
                        .col-sm-8
                            select#marketing_action_id.form-control(v-model="form.marketing_action_id" name="marketing_action_id")
                                option(v-for="m in marketing" :value="m.id") {{m.name}}
                    .form-group(v-show="form.marketing_action_id")
                        .col-sm-8.col-sm-offset-4
                            .alert.alert-info
                                i.fa.fa-gift.mr3
                                span {{descriptionMarketing}}
                    .form-group
                        label.col-sm-4.control-label(for="comments") {{'sale.COMMENTS'|translate}}
                        .col-sm-8
                            textarea#comments.form-control(v-model.lazy="form.comments" name="comments")
                    .form-group
                        .col-sm-8.col-sm-offset-4
                            OptiButton(type="success" :disabled="disabled"
                                text="carts.confirm.goendsale" @confirmed="goEndSale") {{'carts.sale.GOENDSALE'|translate}}
                                template(#icon)
                                    i.fa.fa-shopping-cart

        OptiDimmer(:active="!Object.keys(sale).length || loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import saleHelper from '@/js/app/vue/store/carts/helper/sale'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiProduct from '@/js/app/vue/components/Warehouse/Products/OptiProduct'
    import OptiPayment from '@/js/app/vue/components/Payments/OptiPayment'
    import OptiReceipt from '@/js/app/vue/components/Register/Receipt/OptiReceipt'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiCartSaleAdd',
        components: {
            OptiButton,
            OptiProduct,
            OptiPayment,
            OptiReceipt,
            OptiDimmer
        },
        data() {
            return {
                marketing: [],

                form_angular: {},

                form: {
                    card: {},
                    cardNumber: '',
                    accountNo: '',
                    receipt: {
                        cash_register_id: 0,
                        receipt: ''
                    },
                    marketing_action_id: 0,
                    comments: ''
                },

                payment: [],

                invalid: {
                    payment: true,
                    receipt: true
                },
                loading: false
            }
        },
        mounted() {
            API.post('marketing/active', {
                type: 2
            }).then(res => {
                this.marketing = res.data

                this.marketing.unshift({
                    id: 0,
                    name: 'Brak'
                })
            }).catch(() => {
                this.$notify.error('marketing.notify.ERRORLOADDATA')
            })
        },
        methods: {
            getDiscountCard(typeId) {
                return saleHelper.getDiscountCard(typeId)
            },

            goEndSale() {
                this.loading = true

                API.post('sale/add', {
                    cart: this.sale,
                    sale: this.form,
                    payment: this.payment
                }).then(res => {
                    if(res.data.status) {
                        this.$state.go('app.sale.details', {
                            id: res.data.sale_id
                        })

                        this.$notify.success('sale.notify.SAVE')
                    } else {
                        this.$notify.error('sale.notify.error.GOENDSALE')
                    }
                }).finally(() => {
                    this.loading = false

                    this.$store.dispatch('carts/fetch')
                }).catch(() => {
                    this.$notify.error('sale.notify.error.GOENDSALE')
                })
            }
        },
        computed: {
            sale() {
                return this.$store.getters['carts/getCarts'].sale
            },
            disabled() {
                return this.$store.getters['carts/getDisabled']('sale') || this.invalid.payment || this.invalid.receipt ||
                    !!this.form_angular.$invalid || !!this.form_angular.$pending
            },

            descriptionMarketing() {
                let _return = ''

                this.marketing.forEach(marketing => {
                    if(marketing.id === this.form.marketing_action_id) {
                        _return = marketing.description
                    }
                })

                return _return
            }
        }
    }
</script>

<style scoped lang="less">
    #cart-sale-patient,
    #cart-sale-product {
        margin-bottom: 10px;
    }
</style>