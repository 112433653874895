<template lang="pug">
    .row
        .col-md-8.col-md-offset-2(v-show="!loading")
            form.form-horizontal
                .form-group
                    label.col-sm-5.control-label.star-required(for="type") {{'product.export.TYPE'|translate}}
                    .col-sm-7
                        OptiSelectEnum#type(enum="ProductType" :value="type" @update-enum="t => type = t")
                .form-group
                    .col-sm-7.col-sm-offset-5
                        OptiButton(type="success" @click.native="goExport") {{'product.export.BTN'|translate}}
                            template(#icon)
                                i.fa.fa-upload

        .col-xs-12
            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {EventBus} from '@/js/app/_bridge/EventBus'
    import {EnumProductType} from '@/js/app/vue/enums/Enums'

    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiProductExport',
        components: {
            OptiSelectEnum,
            OptiButton,
            OptiDimmer
        },
        props: {

        },
        data() {
            return {
                type: 0,

                loading: false
            }
        },
        methods: {
            goExport() {
                this.loading = true

                let filename = this.$filters.translate(EnumProductType.memberByValue(this.type).translation) + ' ' +
                    moment().format('YYYY-MM-DD HH:mm:ss') + '.csv'

                EventBus.$emit('ng:emit', {
                    name: 'downloadable',
                    data: {
                        name: 'products/' + filename,
                        status: true
                    }
                })

                API.post('warehouse/products/export', {
                    type: this.type,
                    filename: filename
                }).then(() => {

                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('product.export.notify.error.LOAD')
                })
            }
        }
     }
</script>