<template lang="pug">
    #opti-layout-unit(style="position:relative")
        .row(style="margin-left:0" v-if="!['advertisement', 'glasscase'].includes(selection.type)")
            #opti-layout-unit-navi.hide
                OptiButton#btn-previous-column(@click.native="switchColumn('previous')")
                OptiButton#btn-next-column(@click.native="switchColumn('next')")

            div(style="padding:0" v-for="column in items" :class="getColClass")
                .grid-title(:class="{'text-animated-alert': selection.type === 'panel' && columnSize(column) !== 18, 'background-neighbor-shadow': isNeighborInEditMode(column.id)}")
                    span.text-sm.pull-left {{column.name}}
                    span.pull-right(:class="{'text-animated-alert': columnProducts(column) !== columnSize(column)}") {{columnProducts(column)}} / {{columnSize(column)}}
                    .clearfix

                draggable.grid-mx(:list="column.items" group="brands" v-bind="dragOptions" :data-column="column.id" @end="update")
                    .grid-brand(v-for="brand in column.items" :data-brand-id="brand.id" @mouseleave="brand.open = false")
                        .content(:class="{'content-large': brand.size > 9, 'background-shadow': isInEditMode(brand), 'background-neighbor-shadow': isNeighborInEditMode(column.id)}")
                            .drag-handle.bg-separator.bold(:class="getBrandStyle(brand)")
                                span.pull-right(style="padding-left:5px;padding-right:3px") (+{{brand.supply}})
                                span.pull-right(style="padding-right:4px" :class="{'text-animated-alert': brand.children.length !== brand.size}") {{brand.children.length}} / {{brand.size}}
                                .clearfix

                                div(style="position:absolute;top:3px;left:3px;z-index:20")
                                    button.btn.btn-lg(style="padding:10px 14px" :class="{'btn-primary': !isInEditMode(brand), 'btn-success active': isInEditMode(brand)}"
                                        @click="editProducts(brand)")
                                        i.icon-eyeglasses

                            .content-body
                                img.img-responsive(:src="'img/categories/' + brand.category_id + '.jpg'")
                                div(style="color:yellow;position:absolute;top:4px;right:4px" v-show="brand.type === 'sunglasses'")
                                    i.fa.fa-sun-o.fa-2x
                                    
                                .content-body-footer
                                    div(style="padding-bottom:44px" v-show="brand.changeRequest") {{'layout.unit.CHANGE'|translate}}: {{brand.changeRequest > 0 ? '+' : ''}}{{brand.changeRequest}}
                                    OptiDepositoryInfo(:product="{depository: {stock: brand.stock, ordered: brand.ordered, expected: brand.expected}, quantity: brand.warehouse}")
                                    span.text-gray-light(v-show="brand.in_transit")
                                        i.fa.fa-arrow-down.mr3
                                        span {{brand.in_transit}}
                                    div
                                        span.mr3(:class="{'text-animated-warning': brand.products !== brand.facing}") {{brand.products}} / {{brand.facing}} (+{{brand.supplyAll}})
                                        span(:class="{'text-animated-alert': inRange(brand) < 0, 'text-animated-warning': inRange(brand) > 0}") [{{brand.ranges.min}}-{{brand.ranges.max}}]
                                    div(v-show="brand.facing_items !== brand.facing" :class="{'text-animated-alert': brand.facing_items !== brand.facing}")
                                        a(style="display:block;color:inherit" :href="$state.href('app.layout.details', {id: detailsId, search: ['category_name:=:' + brand.name, 'type:=:' + brand.type]})" target="_blank"
                                            ) {{'layout.unit.facing.DETAILS'|translate}}: {{brand.facing_items}}
                                        span {{'layout.unit.facing.HERE'|translate}}: {{brand.facing}}

                                .grid-brand-controls
                                    .btn-group.dropup(:class="{'open': brand.open}" dropdown="dropdown")
                                        button.btn.btn-lg.btn-primary.btn-rect.dropdown-toggle(dropdown-toggle
                                            :class="{'active': brand.open}" @click="brand.open = !brand.open")
                                            i.fa.fa-cog.fa-lg

                                        ul.dropdown-menu.dropdown-menu-right
                                            li(:class="{'disabled': !object.previous}" :disabled="!object.previous")
                                                a(@click="brandMoveTo(brand, object.previous)")
                                                    i.pull-left.fa.fa-2x.fa-arrow-left
                                                    span.pull-left(style="line-height:2em") {{'layout.unit.PREVIOUS'|translate}}
                                                    .clearfix

                                            li
                                                a(@click="brandMoveTo(brand, 'custom')")
                                                    i.pull-left.fa.fa-2x.fa-arrow-up
                                                    span.pull-left(style="line-height:2em") {{'layout.unit.MOVETO'|translate}}
                                                    .clearfix

                                            li(:class="{'disabled': !object.next}" :disabled="!object.next")
                                                a(@click="brandMoveTo(brand, object.next)")
                                                    i.pull-left.fa.fa-2x.fa-arrow-right
                                                    span.pull-left(style="line-height:2em") {{'layout.unit.NEXT'|translate}}
                                                    .clearfix

                                            li.divider(v-show="permission.edit")
                                            li(v-show="permission.edit")
                                                a
                                                    OptiNumber(header="Facing" :min="object.config.min" :max="object.config.max"
                                                        :value="brand.size" @confirmed="s => updateBrandSize(brand, s)")
                                                        i.pull-left.fa.fa-2x.fa-calculator
                                                        span.pull-left(style="line-height:2em") Facing - {{brand.size}}

                                            li.divider(v-show="permission.edit")
                                            li(v-show="permission.edit")
                                                a(@click="removeBrand(brand)")
                                                    i.pull-left.fa.fa-2x.fa-remove.warn
                                                    span.pull-left.warn(style="line-height:2em") {{'layout.unit.delete.BTN'|translate}}
                                                    .clearfix

            MountingPortal(v-if="dialog.moveTo" mountTo="#vue-modal" append)
                OptiLayoutUnitMoveToDialog(
                    :object="object"

                    @confirmed="s => brandMoveTo(active.brand_to_move, s.id)"
                    @closed="dialog.moveTo = false")

        .row(style="padding-left:15px;margin-top:20px" v-show="permission.edit && !['advertisement', 'glasscase'].includes(selection.type)")
            .text-center(:class="getColClass" v-for="column in items")
                OptiButton(type="success" @click.native="active.column_to_add = column;dialog.add = true") {{'layout.unit.ADD'|translate}}
                    template(#icon)
                        i.fa.fa-plus

        MountingPortal(v-if="dialog.add" mountTo="#vue-modal" append)
            OptiLayoutUnitBrandAddDialog(
                :object="object"

                @confirmed="addBrand"
                @closed="dialog.add = false")

        .row(v-if="['advertisement', 'glasscase'].includes(selection.type)")
            .col-xs-12
                form.form.form-horizontal
                    .form-group
                        label.control-label.col-sm-2(for="layout_advertisement") {{'layout.view.ADVERTISEMENT'|translate}}
                        .col-sm-6
                            OptiUpload#layout_advertisement(
                                prefix="layout_advertisement"
                                :picture="true"

                                :file="'layout_advertisement/' + selection.upload"

                                @update-file="uploadAdvertisement")

        OptiDimmer(:active="loading.object || loading.main")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import draggable from 'vuedraggable'

    import OptiDepositoryInfo from '@/js/app/vue/components/Warehouse/Products/OptiDepositoryInfo'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiUpload from '@/js/app/vue/components/OptiUpload'

    import OptiLayoutUnitMoveToDialog from '@/js/app/vue/components/Layout/Dialogs/Unit/OptiLayoutUnitMoveToDialog'
    import OptiLayoutUnitBrandAddDialog from '@/js/app/vue/components/Layout/Dialogs/Unit/OptiLayoutUnitBrandAddDialog'

    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiLayoutUnit',
        components: {
            draggable,
            OptiDepositoryInfo,
            OptiButton,
            OptiNumber,
            OptiUpload,
            OptiLayoutUnitMoveToDialog,
            OptiLayoutUnitBrandAddDialog,
            OptiDimmer
        },
        props: {
            selection: {
                type: Object,
                required: true
            },
            detailsId: {
                type: Number,
                required: true
            },
            navi: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                object: {},
                items: [],

                active: {
                    brand_id: 0,
                    column: null,
                    brand_to_move: null,
                    column_to_add: null
                },

                dragOptions: {
                    animation: 200,
                    group: 'description',
                    disabled: false,
                    ghostClass: 'ghost'
                },

                permission: {
                    edit: this.$uac.permission('layouts.edit')
                },
                dialog: {
                    moveTo: false,
                    add: false
                },
                loading: {
                    object: true,
                    main: true
                }
            }
        },
        mounted() {
            API.get('layout/object/' + this.$state.params.id, API.id(Math.random())).then(res => {
                this.object = res.data
            }).finally(() => {
                this.loading.object = false
            }).catch(() => {
                this.$notify.error('layout.unit.notify.error.UNIT')
            })

            API.get('layout/room/object/' + this.$state.params.id, API.id(Math.random())).then(res => {
                this.items = res.data

                if(this.$state.params.select && this.items.length) {
                    this.editProducts(this.items[this.$state.params.select === 'first' ? 0 : this.items.length - 1].items[0])
                }
            }).finally(() => {
                this.loading.main = false
            }).catch(() => {
                this.$notify.error('layout.unit.notify.error.LOAD')
            })
        },
        methods: {
            columnProducts(column) {
                return column.items.reduce((total, brand) => {
                    return total + brand.children.length
                }, 0)
            },
            columnSize(column) {
                if(this.selection.type === 'panel') {
                    return 18
                }

                return column.items.reduce((total, brand) => {
                    return total + brand.size
                }, 0)
            },

            isInEditMode(brand) {
                return this.active.brand_id === brand.id
            },
            isNeighborInEditMode(column) {
                return this.active.column !== null && this.active.column - 1 <= column && column <= this.active.column + 1
            },

            getBrandStyle(brand) {
                if(brand.children.length < brand.size) return 'grid-brand-warning'
                if(brand.children.length > brand.size) return 'grid-brand-danger'

                return 'grid-brand-success'
            },

            editProducts(brand) {
                this.active.brand_id = brand.id
                this.active.column = null

                this.navi.previous = 'brak'
                this.navi.next = 'brak'

                this.items.forEach(column => {
                    column.items.forEach(brand => {
                        if(brand.id === this.active.brand_id) {
                            this.active.column = column.id
                        }
                    })
                })

                if(this.active.column !== null) {
                    this.navi.previous = !this.active.column ? null : this.active.column
                    this.navi.next = this.active.column === this.items.length - 1 ? null : this.active.column + 2
                }

                this.$state.go('app.layout.grid.unit.products', {
                    id: this.$state.params.id,
                    brandId: this.active.brand_id,
                    random: Math.random()
                }, {
                    location: false
                })
            },

            inRange(brand) {
                let stock = parseInt(brand.stock) + parseInt(brand.ordered) + parseInt(brand.expected),
                    min = brand.facing + brand.ranges.min,
                    max = brand.facing + brand.ranges.max

                if(stock < min) return -1
                if(stock > max) return 1
                return 0
            },

            brandMoveTo(brand, newObjectId) {
                this.active.brand_to_move = brand

                if(newObjectId === 'custom') {
                    this.dialog.moveTo = true
                } else if(newObjectId) {
                    this.dialog.moveTo = false
                    brand.open = false
                    this.loading.main = true

                    API.post('layout/brand/moveto', {
                        id: this.active.brand_to_move.id,
                        objectId: newObjectId
                    }).then(res => {
                        this.items = res.data
                    }).finally(() => {
                        this.goSelectBrand()

                        this.loading.main = false
                    }).catch(() => {
                        this.$notify.error('layout.unit.notify.error.MOVETO')
                    })
                }
            },
            removeBrand(brand) {
                brand.open = false

                if(confirm(this.$filters.translate('layout.unit.delete.confirm.MESSAGE') + '\n' + brand.name + ' - ' + brand.size)) {
                    this.loading.main = true

                    API.delete('layout/brand/' + brand.id).then(res => {
                        this.items = res.data
                    }).finally(() => {
                        this.goSelectBrand()

                        this.loading.main = false
                    }).catch(() => {
                        this.$notify.error('layout.unity.notify.error.DELETE')
                    })
                }
            },
            addBrand(selected) {
                this.loading.main = true
                this.dialog.add = false

                API.post('layout/brand/create', {
                    column: this.active.column_to_add.id,
                    layout_object_id: this.object.id,
                    category_id: selected.category.id,
                    size: selected.size
                }).then(res => {
                    this.items = res.data
                }).finally(() => {
                    this.goSelectBrand()

                    this.loading.main = false
                }).catch(() => {
                    this.$notify.error('layout.unit.notify.error.ADD')
                })
            },
            updateBrandSize(brand, size) {
                this.loading.main = true

                brand.size = size

                API.patch('layout/brand', {
                    id: brand.id,
                    size: brand.size
                }).then(res => {
                    this.goSelectBrand()

                    this.items = res.data
                }).finally(() => {
                    this.loading.main = false
                }).catch(() => {
                    this.$notify.error('layout.unit.notify.error.SIZE')
                })
            },

            update(e) {
                this.loading.main = true

                let data = {
                    id: parseInt(e.clone.getAttribute('data-brand-id')),
                    column: parseInt(e.to.getAttribute('data-column')),
                    position: 0
                }

                this.items.forEach(column => {
                    if(column.id === data.column) {
                        column.items.forEach((brand, index) => {
                            if(brand.id === data.id) {
                                if(index === column.items.length - 1) {
                                    index += 100
                                }

                                data.position = index
                            }
                        })
                    }
                })

                API.put('layout/brand/save/position', data).then(res => {
                    this.items = res.data
                }).finally(() => {
                    this.goSelectBrand()

                    this.loading.main = false
                }).catch(() => {
                    this.$notify.error('layout.unit.notify.error.MOVETO')
                })
            },

            goSelectBrand() {
                let brand_id = this.$state.params.brandId

                if(brand_id) {
                    this.items.forEach(column => {
                        column.items.forEach(brand => {
                            if(brand.id === brand_id) {
                                this.editProducts(brand)
                            }
                        })
                    })
                }
            },

            switchColumn(type) {
                let go_to_brand = null

                this.items.forEach(column => {
                    if(column.id === this.navi[type] - 1) {
                        column.items.forEach(brand => {
                            if(!go_to_brand) {
                                go_to_brand = brand
                            }
                        })
                    }
                })

                this.editProducts(go_to_brand)
            },

            uploadAdvertisement(upload) {
                if(upload) {
                    this.loading.main = true

                    upload = upload.includes('/') ? upload.split('/')[1] : upload

                    API.post('layout/object/upload', {
                        object_id: this.selection.id,
                        upload: upload
                    }).then(res => {
                        if(parseInt(res.data) > 0) {
                            this.selection.upload = upload
                        }
                    }).finally(() => {
                        this.loading.main = false
                    }).catch(() => {
                        this.$emit('error', 'layout.unit.notify.error.UPLOAD')
                    })
                }
            }
        },
        computed: {
            getColClass() {
                let len = (12 / this.items.length).toFixed()

                return 'col-sm-' + len
            }
        }
    }
</script>

<style lang="less" scoped>
    .grid-brand {
        cursor: move;

        &.ghost > .content {
            box-shadow: 0 0 0 6px red !important;
        }
    }

    ul.dropdown-menu {
        cursor: default;

        > li {
            &:not([class="divider"]) {
                cursor: pointer !important;

                > a > .form-control {
                    background: transparent;
                    border: none;
                    padding: 0;
                    color: inherit;
                }
            }
        }
    }
</style>