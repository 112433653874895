<template lang="pug">
    .catalog(style="position:relative")
        .row(v-show="category && category.children.length")
            .col-md-2.col-sm-4(v-for="child in category.children")
                .category(v-show="!child.isSpecial")
                    a(:href="$state.href('app.catalog.category', {id: child.id, special: child.isSpecial ? category.id : '', page: 1, productId: product_id, departmentId: department_id})")
                        .thumbnail-container.text-center
                            img.img-thumbail(:src="'img/categories/' + child.id + '.jpg'")
                        .header
                            .title {{child.name}}

        .row(style="position:relative" v-show="category.has_products")
            .col-xs-12
                .row
                    .col-sm-7
                        OptiButtonFilter.mr3(
                            :selected="filter.stock.local"
                            :title="'catalog.filter.storage.LOCAL'|translate"

                            @click="filter.stock.local = !filter.stock.local; load()")

                        OptiButtonFilter.mr3(
                            :selected="filter.stock.main"
                            :title="'catalog.filter.storage.MAIN'|translate"

                            @click="filter.stock.main = !filter.stock.main; load()")

                        OptiButtonFilter(
                            :selected="filter.stock.temporaries"
                            :title="'catalog.filter.storage.TEMPORARIES'|translate"

                            @click="filter.stock.temporaries = !filter.stock.temporaries; load()")

                        OptiButtonFilter.ml3(
                            v-show="!hide"
                            :selected="filter.stock.tickets"
                            :title="'catalog.filter.storage.TICKETS'|translate"

                            @click="filter.stock.tickets = !filter.stock.tickets")

                    .col-sm-5
                        OptiSelectDepartment#department_salon.pull-right(style="max-width:130px"
                            :department-id="department_id"
                            :simple="true"

                            @selected="updateDepartment")

                        a.btn.btn-purple.mr3.pull-right(style="padding-top:9px;padding-bottom:9px"
                            v-show="category.substitute.category_id"
                            :href="$state.href('app.catalog.category', {id: category.substitute.category_id, special: category.substitute.special_id, page: 1, productId: 0, departmentId: department_id})"
                            ) {{category.substitute.button_name}}


                .row(v-if="category.type === 'PRODUCT'")
                    OptiProductStatusFilter(
                        :statuses="filter.statuses"
                        :sorting="filter.sorting"

                        @selectStatus="selectStatus"
                        @empty="selectEmptyStatus"
                        @allStatuses="selectAll"
                        @sorting="changeSorting"
                        @changeSortDirection="changeSortDirection")

                .row(style="position:relative")
                    .col-md-4.col-sm-6(style="margin-top:5px;margin-bottom:15px" v-for="product in products" :key="product.id")
                        OptiProductInfo(
                            :product="product"
                            :department-id="department_id"
                            :tickets="filter.stock.tickets"
                            :btn-show="true"
                            :active="product_id === product.id")

                    OptiNavigator(
                        :paginator="paginator"
                        :loading="loading.products.main"

                        @load="p => load(p)")

                .row
                    .col-xs-12.text-center.text-muted(style="padding:10px 0" v-show="!Object.keys(products).length")
                        i.fa.fa-exclamation-circle.mr3
                        span {{'catalog.product.notify.EMPTY'|translate}}

                    .col-xs-12(style="margin-top:10px")
                        OptiPaginator(
                            :paginator="paginator"

                            @changed-page="load")

                        a.pull-right(style="color:inherit;white-space:nowrap;text-decoration:none;cursor:pointer"
                            :title="'catalog.TITLESTOCKWIKI'|translate" v-if="!loading.products.summary"
                            href="https://wiki.eopti.pl/oprogramowanie:system_eopti:stany_katalog" target="_blank")
                            span.mr3 {{summary.brands.available}}/{{summary.brands.all}} {{'catalog.BRANDS'|translate}} |

                            i.fa.fa-fw.fa-dropbox.text-gray-light
                            span.mr3 {{summary.stock.available}}/{{summary.stock.all}}
                            i.fa.fa-fw.fa-envelope.text-gray-light
                            span.mr3 {{summary.ordered.available}}/{{summary.ordered.all}}
                            i.fa.fa-fw.fa-car.text-gray-light
                            span.mr3 {{summary.expected.available}}/{{summary.expected.all}}
                            i.fa.fa-fw.fa-building-o.text-gray-light
                            span.mr3 {{summary.warehouse}} |

                            i.fa.fa-flag-checkered.text-gray-light.mr3(title="facing")
                            span {{summary.facing.available}}/{{summary.facing.all}}

                        i.fa.fa-cog.fa-spin.pull-right(v-show="loading.products.summary")

                    OptiDimmer(:active="loading.products.main")

        OptiCatalogLenses(
            v-if="category.type === 'LENSES'"

            :category-id="category.id"
            :price-groups="category.priceGroups"

            @update-group="g => category.priceGroups = g")

        OptiDimmer(:active="loading.categories")
</template>

<script>
import {API} from '@/js/app/vue/api'

import OptiSelectDepartment from '@/js/app/vue/components/Select/OptiSelectDepartment'
import OptiProductInfo from '@/js/app/vue/components/Warehouse/Products/OptiProductInfo'
import OptiPaginator from '@/js/app/vue/components/OptiPaginator'
import OptiDepositoryInfo from '@/js/app/vue/components/Warehouse/Products/OptiDepositoryInfo'
import OptiCatalogLenses from '@/js/app/vue/components/Catalog/Lenses/OptiCatalogLenses'
import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
import OptiNavigator from '@/js/app/vue/components/OptiNavigator'
import OptiProductStatusFilter from '@/js/app/vue/components/Catalog/Filters/OptiProductStatusFilter'
import OptiButtonFilter from '@/js/app/vue/components/Button/OptiButtonFilter'
import OptiButton from '@/js/app/vue/components/Button/OptiButton'

export default {
    name: 'OptiCatalogCategory',
    components: {
        OptiButton,
        OptiButtonFilter,
        OptiProductStatusFilter,
        OptiNavigator,
        OptiSelectDepartment,
        OptiProductInfo,
        OptiPaginator,
        OptiDepositoryInfo,
        OptiCatalogLenses,
        OptiDimmer
    },
    data() {
        return {
            category: {
                children: [],
                substitute: {}
            },
            category_id: this.$state.params.id,

            filter: {
                stock: {
                    local: false,
                    main: false,
                    temporaries: true,
                    tickets: false
                },
                statuses: {
                    all: true,
                    mh: true,
                    pi1: true,
                    pi: true,
                    ac: true,
                    po: true,
                    not_selected: true
                },
                sorting: {
                    asc: true,
                    sortBy: 'lp'
                }
            },

            special: this.$state.params.id === '-' ? '' :
                (this.$state.params.id !== this.$state.params.special ? this.$state.params.special : ''),
            department_id: this.$state.params.departmentId && this.$state.params.departmentId !== '49' ?
                parseInt(this.$state.params.departmentId) : this.$uac.user.activeDepartmentId,

            paginator: {
                pages: 10,
                page: parseInt(this.$state.params.page)
            },

            products: [],
            product_id: this.$state.params.productId ? parseInt(this.$state.params.productId) : 0,

            summary: {},

            hide: ['SłS', 'Katalog'].includes(this.$uac.user.activeRole.short),
            loading: {
                categories: true,
                products: {
                    main: true,
                    summary: true
                }
            }
        }
    },
    mounted() {
        if (window.localStorage.getItem('category-products-filter') !== null) {
            this.filter.stock = JSON.parse(window.localStorage.getItem('category-products-filter'))
        }

        if (window.localStorage.getItem('category-products-selection') !== null) {
            this.filter.statuses = JSON.parse(window.localStorage.getItem('category-products-selection'))
        }

        if (window.localStorage.getItem('category-products-sorting') !== null) {
            this.filter.sorting = JSON.parse(window.localStorage.getItem('category-products-sorting'))
        }

        this.loadCategory()
        this.load()
    },
    methods: {
        selectEmptyStatus() {
            let filterStatuses = this.filter.statuses,
                hasSelectedStatus = filterStatuses.mh || filterStatuses.pi1 || filterStatuses.pi || filterStatuses.ac || filterStatuses.po

            if (filterStatuses.not_selected && hasSelectedStatus) {
                Object.keys(filterStatuses).forEach(key => filterStatuses[key] = false)
                filterStatuses.not_selected = true
            }

            if (!filterStatuses.not_selected && hasSelectedStatus) {
                filterStatuses.all = false
            }

            this.load()
        },
        changeSorting(x) {
            let sortBy = this.filter.sorting.sortBy

            sortBy = x

            this.load()
        },
        changeSortDirection(x) {
            let direction = this.filter.sorting.asc

            direction = x

            this.load()
        },
        selectStatus(x) {
            let filterStatuses = this.filter.statuses

            if (!x || !(filterStatuses.mh && filterStatuses.pi1 && filterStatuses.pi && filterStatuses.ac && filterStatuses.po)) {
                filterStatuses.not_selected = false
                filterStatuses.all = false
            }

            this.load()
        },
        selectAll() {
            let filterStatuses = this.filter.statuses,
                isAllSelected = filterStatuses.all

            Object.keys(filterStatuses).forEach(key => filterStatuses[key] = isAllSelected)

            this.load()
        },
        load(page) {
            if (typeof page === 'undefined') {
                page = this.paginator.page
            }

            this.loading.products.main = true

            API.post('catalog/category/products', {
                categoryId: this.category_id,
                filter: this.filter.stock,
                page: page,
                special: this.special,
                departmentId: this.department_id,
                statuses: this.filter.statuses,
                sorting: this.filter.sorting
            }).then(res => {
                this.paginator.pages = res.data.meta.pagination.total_pages
                this.paginator.page = res.data.meta.pagination.current_page > this.paginator.pages ? this.paginator.pages : res.data.meta.pagination.current_page

                delete res.data.meta

                this.products = res.data

                window.localStorage.setItem('category-products-filter', JSON.stringify(this.filter.stock))
                window.localStorage.setItem('category-products-selection', JSON.stringify(this.filter.statuses))
                window.localStorage.setItem('category-products-sorting', JSON.stringify(this.filter.sorting))

                if (this.category.has_products) {
                    this.loadSummary()
                }

                this.setUrl()
            }).finally(() => {
                this.loading.products.main = false
            }).catch(() => {
                this.$notify.error('catalog.product.notify.error.PRODUCTS')
            })
        },
        setUrl() {
            this.$state.go('app.catalog.category', {
                page: this.paginator.page,
                special: this.special,
                productId: this.product_id,
                departmentId: this.department_id
            }, {
                notify: false
            })
        },
        loadCategory() {
            API.get('catalog/category/' + this.category_id + '/' + this.special).then(res => {
                this.category = res.data

                this.$emit('update-breadcrumb', this.category.breads, this.product_id, this.department_id, this.category.name)
            }).finally(() => {
                this.loading.categories = false
            }).catch(() => {
                this.$notify.error('catalog.notify.error.CATALOG')
            })
        },
        loadSummary() {
            this.loading.products.summary = true

            API.post('catalog/summary', {
                category_id: this.category_id,
                departmentId: this.department_id,
                filter: this.filter.stock,
                statuses: this.filter.statuses,
            }, API.id(Math.random())).then(res => {
                this.summary = res.data
            }).finally(() => {
                this.loading.products.summary = false
            }).catch(() => {
                this.$notify.error('catalog.notify.error.SUMMARY')
            })
        },

        updateDepartment(department) {
            if (this.department_id !== department.id) {
                this.department_id = department.id

                this.load()

                this.$emit('update-breadcrumb', this.category.breads, this.product_id, this.department_id, this.category.name)
            }
        }
    }
}
</script>