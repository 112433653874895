<template lang="pug">
    .col-xs-12(v-if="showChangeShapeFrame")
        .panel.panel-primary
            .panel-heading(style="cursor:pointer" @click="toggle = !toggle")
                i.fa.fa-navicon.mr3
                span {{'tasks.extension.EXTENSION'|translate}}

                 .pull-right
                     i.fa(:class="{'fa-arrow-down': !toggle, 'fa-arrow-up': toggle}")

            .panel-body(v-show="toggle")
                .row(v-show="frame.blockOverwrite")
                    .col-xs-12
                        .alert.alert-warning
                            i.fa.fa-exclamation-circle.mr3
                            span {{'tasks.extension.INFOLOCK'|translate}}
                .row(v-show="frame.productId && frame.productId === frame.productIdOverwrite")
                    .col-xs-12
                        .alert.alert-warning
                            i.fa.fa-exclamation-circle.mr3
                            span {{'tasks.extension.SAMEFRAME'|translate}}
                .row
                    .col-md-6
                        .bold(style="padding-bottom:5px") {{'tasks.extension.FRAME'|translate}}
                        OptiSelectFrame(
                            :product="extension"
                            :disabled="block || frame.blockOverwrite"

                            @selected="selectedExtension")
                    .col-md-6
                        img(style="width:100%" v-if="frame.productIdOverwrite"
                            :src="'thumb/product/ids/' + (frame.productIdOverwrite ? frame.productIdOverwrite : 0) + '.jpg'")

            .panel-footer(v-show="toggle")
                OptiButton(type="success" :disabled="block" v-show="frame.blockOverwrite"
                    @click.native="toggleBlockOverwrite") {{'tasks.extension.action.UNLOCK'|translate}}
                    template(#icon)
                        i.fa.fa-unlock
                OptiButton(type="danger" :disabled="block" v-show="!frame.blockOverwrite"
                    @click.native="toggleBlockOverwrite") {{'tasks.extension.action.LOCK'|translate}}
                    template(#icon)
                        i.fa.fa-lock

                OptiButton.pull-right(type="danger" :disabled="!!(block || !frame.productIdOverwrite || frame.blockOverwrite)"
                    @click.native="goFrameExtCancel") {{'tasks.extension.action.CANCEL'|translate}}
                    template(#icon)
                        i.fa.fa-remove
</template>

<script>
    import OptiSelectFrame from '@/js/app/vue/components/Select/OptiSelectFrame'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiTaskDetailsLensesFrameOverwrite',
        components: {
            OptiSelectFrame,
            OptiButton
        },
        props: {
            frame: {
                type: Object,
                required: true
            },
            shape: {
                type: Object,
                required: true
            },
            extension: {
                type: Object,
                required: true
            },

            block: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                toggle: false
            }
        },
        mounted() {

        },
        methods: {
            selectedExtension(product) {
                this.frame.productIdOverwrite = product.id
                this.frame.productNameOverwrite = product.name

                this.$emit('frame-overwrite-was-updated', product)
            },
            goFrameExtCancel() {
                this.frame.productIdOverwrite = 0
                this.frame.productNameOverwrite = ''

                this.$emit('frame-overwrite-was-updated', {})
            },

            toggleBlockOverwrite() {
                this.frame.blockOverwrite = !this.frame.blockOverwrite

                this.$emit('frame-overwrite-was-updated')
            }
        },
        computed: {
            showChangeShapeFrame() {
                let direction = this.shape.direction.top || this.shape.direction.bottom ||
                        this.shape.direction.temporal || this.shape.direction.nasal

                return this.frame.productIdOverwrite || (direction && !this.block)
            }
        }
    }
</script>
