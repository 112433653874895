<template lang="pug">
    .task-ipad
        h4.page-header
            span {{'measurement.compare.HEADING'|translate}}

            OptiButton.pull-right(style="margin-top:-10px" v-if="refType && refId" @click.native="download"
                ) {{'measurement.compare.excel.BTN'|translate}}
                template(#icon)
                    i.fa.fa-download

        .table-responsive(style="position:relative")
            table.table.middle.table-striped
                thead
                    tr
                        th
                        th
                            span {{'tasks.measurement.individual.table.thead.PD'|translate}}
                            sub {{'tasks.measurement.individual.table.thead.direction.R'|translate}}
                        th
                            span {{'tasks.measurement.individual.table.thead.PD'|translate}}
                            sub {{'tasks.measurement.individual.table.thead.direction.L'|translate}}
                        th
                            span {{'tasks.measurement.individual.table.thead.BT'|translate}}
                            sub {{'tasks.measurement.individual.table.thead.direction.R'|translate}}
                        th
                            span {{'tasks.measurement.individual.table.thead.BT'|translate}}
                            sub {{'tasks.measurement.individual.table.thead.direction.L'|translate}}
                        th
                            i.fa.fa-arrows-v(:title="'tasks.frame.HEIGHT'|translate")
                        th
                            i.fa.fa-arrows-h(:title="'tasks.frame.WIDTH'|translate")
                        th
                            div(:title="'tasks.INCLINATION'|translate") PA
                        th
                            span CVD
                            sub {{'prescription.RIGHT'|translate}}
                        th
                            span CVD
                            sub {{'prescription.LEFT'|translate}}
                        th(:title="'tasks.FFFA'|translate") FFFA

                tbody(v-if="measurement.id && measurementJawroVisu.id")
                    tr
                        th VisuReal
                        td {{measurement.pdR|number(1)}}
                        td {{measurement.pdL|number(1)}}
                        td
                            .bt-row
                                div(:title="btTitle('VisuReal', 'main', measurement, 'r')") {{measurement.btR|number(1)}}
                                .text-muted(v-show="measurement.rawBtR && measurement.btR !== measurement.rawBtR"
                                    :title="btTitle('VisuReal', 'raw', measurement, 'r')") {{measurement.rawBtR|number(1)}}
                        td
                            .bt-row
                                div(:title="btTitle('VisuReal', 'main', measurement, 'l')") {{measurement.btL|number(1)}}
                                .text-muted(v-show="measurement.rawBtL && measurement.btL !== measurement.rawBtL"
                                    :title="btTitle('VisuReal', 'raw', measurement, 'l')") {{measurement.rawBtL|number(1)}}
                        td {{measurement.frameB|number(1)}}
                        td {{measurement.frameA|number(1)}}
                        td {{measurement.wpa|number(1)}}
                        td {{measurement.cvd|number(1)}}
                        td {{measurement.cvdLeft|number(1)}}
                        td {{measurement.fffa|number(1)}}

                    tr
                        th(style="color:deeppink") JawroVisu
                        td {{measurementJawroVisu.pd_r|number(1)}}
                        td {{measurementJawroVisu.pd_l|number(1)}}
                        td
                            .bt-row
                                div(:title="btTitle('JawroVisu', 'main', measurementJawroVisu, 'r')") {{measurementJawroVisu.bt_r|number(1)}}
                                .text-muted(v-show="measurementJawroVisu.raw_bt_r && measurementJawroVisu.bt_r !== measurementJawroVisu.raw_bt_r"
                                    :title="btTitle('JawroVisu', 'raw', measurementJawroVisu, 'r')") {{measurementJawroVisu.raw_bt_r|number(1)}}
                        td
                            .bt-row
                                div(:title="btTitle('JawroVisu', 'main', measurementJawroVisu, 'l')") {{measurementJawroVisu.bt_l|number(1)}}
                                .text-muted(v-show="measurementJawroVisu.raw_bt_l && measurementJawroVisu.bt_l !== measurementJawroVisu.raw_bt_l"
                                    :title="btTitle('JawroVisu', 'raw', measurementJawroVisu, 'l')") {{measurementJawroVisu.raw_bt_l|number(1)}}
                        td {{measurementJawroVisu.frame_height|number(1)}}
                        td {{measurementJawroVisu.frame_width|number(1)}}
                        td {{measurementJawroVisu.pa|number(1)}}
                        td {{measurementJawroVisu.cvd_r|number(1)}}
                        td {{measurementJawroVisu.cvd_l|number(1)}}
                        td {{measurementJawroVisu.fffa|number(1)}}

                    tr
                        th Różnica
                        td(:class="{'warn': measurement.pdR !== measurementJawroVisu.pd_r}"
                            ) {{measurement.pdR - measurementJawroVisu.pd_r|number(1)}}
                        td(:class="{'warn': measurement.pdL !== measurementJawroVisu.pd_l}"
                            ) {{measurement.pdL - measurementJawroVisu.pd_l|number(1)}}
                        td(:class="{'warn': measurement.btR !== measurementJawroVisu.bt_r}"
                            ) {{measurement.btR - measurementJawroVisu.bt_r|number(1)}}
                        td(:class="{'warn': measurement.btL !== measurementJawroVisu.bt_l}"
                            ) {{measurement.btL - measurementJawroVisu.bt_l|number(1)}}
                        td(:class="{'warn': measurement.frameB !== measurementJawroVisu.frame_height}"
                            ) {{measurement.frameB - measurementJawroVisu.frame_height|number(1)}}
                        td(:class="{'warn': measurement.frameA !== measurementJawroVisu.frame_width}"
                            ) {{measurement.frameA - measurementJawroVisu.frame_width|number(1)}}
                        td(:class="{'warn': measurement.wpa !== measurementJawroVisu.pa}"
                            ) {{measurement.wpa - measurementJawroVisu.pa|number(1)}}
                        td(:class="{'warn': measurement.cvd !== measurementJawroVisu.cvd_r}"
                            ) {{measurement.cvd - measurementJawroVisu.cvd_r|number(1)}}
                        td(:class="{'warn': measurement.cvdLeft !== measurementJawroVisu.cvd_l}"
                            ) {{measurement.cvdLeft - measurementJawroVisu.cvd_l|number(1)}}
                        td(:class="{'warn': measurement.fffa !== measurementJawroVisu.fffa}"
                            ) {{measurement.fffa - measurementJawroVisu.fffa|number(1)}}

                tbody(v-else)
                    tr
                        td.text-center.text-muted(colspan="100")
                            i.fa.fa-exclamation-circle.mr3
                            span {{'measurement.compare.EMPTY'|translate}}
</template>

<script>
    import {TaskMeasurementTitle} from '@/js/app/vue/components/Tasks/TaskMeasurementTitle'
    import {DownloadFileHelper} from '@/js/app/vue/helpers/DownloadFileHelper'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiTaskDetailsIpadCompare',
        components: {
            OptiButton

        },
        props: {
            refType: {
                type: String,
                required: false,
                default: ''
            },
            refId: {
                type: Number,
                required: false,
                default: 0
            },

            measurement: {
                type: Array | Object,
                required: true
            },
            measurementJawroVisu: {
                type: Array | Object,
                required: true
            }
        },
        methods: {
            btTitle(app, type, measurement, side) {
                return TaskMeasurementTitle.title(app, type, measurement, side)
            },
            download() {
                let filename = 'measurements_comparison/' + this.refType + '_' + this.refId + '_' + moment().format('YYYY-MM-DD') + '.xls'

                DownloadFileHelper.post('task/measurements/comparison-excel', filename, {
                    ref_type: this.refType,
                    ref_id: this.refId
                })
            }
        }
    }
</script>
