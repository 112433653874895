<template lang="pug">
    .panel.panel-primary(style="position:relative; padding-bottom:0")
        .panel-heading
            i.fa.fa-navicon.mr3
            span {{'catalog.product.chart.HEADING'|translate}}

        .panel-body(style="padding-bottom:0")
            .row
                .col-xs-12.text-right(style="margin-bottom:3px")
                    .col-xs-12
                        OptiButtonHistory(data="product_selection" :id="product.id")
            .row
                OptiProductStatistic(v-if="product"
                    :product="product"
                    :sum_reclamations="sum_reclamations"

                    @selection_id="v => selectionId = v"
                    @out_of_production="$emit('out_of_production')"
                    @scroll="v => $emit('scroll', v)")
            .row
                .text-center(style="cursor:pointer; padding-bottom: 10px" @click="show = !show")
                    span(style="margin-right: 10px" v-if="!trash && !product.temp_product")
                        i.fa(:class="{'fa-arrow-up': !show, 'fa-arrow-down': show}")

                    span.text-bold.align-middle {{'catalog.product.chart.SUB_HEADING'|translate}}

                    span(style="margin-left: 10px" v-if="!trash && !product.temp_product")
                        i.fa(:class="{'fa-arrow-up': !show, 'fa-arrow-down': show}")
            .row(v-if="!trash && !product.temp_product && show")
                LineChart(
                    :chart-data="chartData"
                    :styles="styles"
                    :options="options"
                    ref="chart")

                OptiChartTooltip(
                    :model="check"
                    :chart-data="chartData"
                    :chart="$refs.chart")
                    template(#default="{body}")
                        .panel.panel-default
                            .panel-heading
                                span.text-center(style="font-size:18px; display:block;") {{body.tooltip.title[0]}}
                                span.text-center.text-muted(style="display:block;") {{ translateDate(body.tooltip.body[0].lines[0].start, body.tooltip.title[0]) }}

                            .panel-body
                                table.table-condensed
                                    tbody
                                        template
                                            tr(v-for="row in body.tooltip.body" :key="row.id")
                                                td.text-center {{ row.lines[0].label }}:
                                                th.text-center {{ row.lines[0].stock }}

                                        tr(v-if="body.tooltip.body[0].lines[0].order")
                                            td.text-center {{'catalog.product.chart.SUPPLY'|translate}}:
                                            th.text-center {{body.tooltip.body[0].lines[0].order}}
            .row(v-if="trash")
                .col-xs-12
                    .alert.alert-info
                        .text-center
                            i.fa.fa-info-circle.mr3
                            span {{'catalog.product.chart.NO_ACTIVITY'|translate}}

            .row(v-if="product.temp_product")
                .col-xs-12
                    .alert.alert-info
                        .text-center
                            i.fa.fa-info-circle.mr3
                            span {{'catalog.product.chart.TEMP_PROD'|translate}}

            .row(style="margin-top:15px")
                OptiProductAvailabilityChart(
                    v-if="selectionId"
                    :selectionId="selectionId")

        OptiDimmer(:active="active")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import LineChart from '@/js/app/vue/components/Chart/Base/LineChart'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiChartTooltip from '@/js/app/vue/components/Chart/Base/OptiChartTooltip'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiProductStatistic from '@/js/app/vue/components/Warehouse/Products/Details/OptiProductStatistic'
    import OptiButtonHistory from '@/js/app/vue/components/Button/OptiButtonHistory'
    import OptiProductAvailabilityChart
        from '@/js/app/vue/components/Warehouse/Products/Details/OptiProductAvailabilityChart'

    export default {
        name: 'OptiProductStatisticChart',
        components: {
            OptiProductAvailabilityChart,
            OptiButtonHistory,
            OptiProductStatistic,
            OptiButton,
            OptiChartTooltip,
            OptiDimmer,
            LineChart
        },
        props: {
            product: {
                type: Object,
                required: true
            },
            sum_reclamations: {
                type: Number,
                required: false
            }
        },
        data() {
            return {
                trash: false,
                check: null,
                checked: false,
                active: false,
                selectionId: null,
                show: true,

                chartData: null,
                styles: {
                    height: '350px',
                    backgroundColor: 'rgba(255,255,255, 0.8)'
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    interaction: {
                        mode: 'index'
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                precision: 0,
                                suggestedMax: 5,
                                suggestedMin: -2
                            }
                        }]
                    },
                    tooltips: {
                        backgroundColor: 'rgba(189,184,173)',
                        mode: 'index',
                        position: 'average',
                        intersect: false,
                        callbacks: {
                            label(tooltipItems, data) {
                                return {
                                    label: data.datasets[tooltipItems.datasetIndex].label,
                                    stock: data.datasets[tooltipItems.datasetIndex]['data'][tooltipItems.index],
                                    start: data.datasets[1]['dates'][tooltipItems.index].start,
                                    heading: data.labels[tooltipItems.index],
                                    order: data.datasets[1].orders[tooltipItems.index]
                                }
                            },
                        },
                        enabled: false,
                        custom: tooltip => {
                            this.check = tooltip
                        }
                    }
                }
            }
        },
        mounted() {
            this.startUp()
        },
        methods: {
            translateDate(date, check) {
                let start = moment(date),
                    endDate = start.clone().endOf('week'),
                    month = start.format('MMMM')

                if(check === 'Start') {
                    return `${start.format('D MMMM Y')}`
                }

                if(endDate.format('MMMM') !== month) {
                    return `${start.format('D MMMM')} - ${endDate.format('D MMMM Y')}`
                }

                return `${start.format('D')} - ${endDate.format('D MMMM Y')}`

            },
            startUp() {
                if(!this.product.temp_product) {
                    this.active = true
                    API.post('warehouse/product/drawChart', {
                        product_id: this.product.id
                    }).then(res => {
                        this.chartData = res.data.chartData
                    }).then(() => {
                        this.trash = this.checkIfDead()
                    }).catch(() => {
                        this.$notify.error('catalog.product.notify.error.CHART')
                    }).finally(() => {
                        this.active = false
                    })
                }
            },
            checkIfDead() {
                const allEqual = arr => arr.every(val => val === arr[0])

                if(this.chartData && this.chartData.datasets) {
                    let array = this.chartData.datasets

                    return allEqual(array[0].data) && allEqual(array[1].data)
                }

                return true
            }
        }
    }
</script>