import { render, staticRenderFns } from "./OptiLayoutUnit.vue?vue&type=template&id=1fea1420&scoped=true&lang=pug&"
import script from "./OptiLayoutUnit.vue?vue&type=script&lang=js&"
export * from "./OptiLayoutUnit.vue?vue&type=script&lang=js&"
import style0 from "./OptiLayoutUnit.vue?vue&type=style&index=0&id=1fea1420&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fea1420",
  null
  
)

export default component.exports