<template lang="pug">
    OptiDialog
        template(#header)
            i.fa.fa-briefcase.mr3
            span {{'patient.offer.table.dialog.OFFER'|translate}} \#{{offerPreview.id}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="$emit('closed', rated)")

        template(#body)
            .row(v-if="!loading")
                .col-sm-4.text-center.pb
                    b.mr3 {{'patient.breadcrumb.PATIENT'|translate}}
                    OptiPatient(:data="offerPreview.patient")
                .col-sm-4.text-center.pb
                    b.mr3 {{'patient.offer.table.department.USER'|translate}}
                    span {{offerPreview.department.name}}
                .col-sm-4.text-center.pb
                    b.mr3 {{'catalog.offer.created.AT'|translate}}
                    span {{offerPreview.created_at}}

                .col-sm-4.text-center.pb
                    b.mr3 {{'patient.offer.table.COUNT_PRODUCTS'|translate}}
                    span {{offerPreview.count_products}}
                .col-sm-4.text-center.pb
                    b.mr3 {{'catalog.offer.created.BY'|translate}}
                    span {{offerPreview.user.firstName}} {{offerPreview.user.lastName}}
                .col-sm-4.text-center.pb
                    .space-around
                        OptiCatalogOfferFavorites(
                            :favorites="offerPreview.favorites"
                            :selected="offerPreview.products.map(p => p.id)"
                            :department-id="offerPreview.department_id")

                        OptiRate(
                            :header="true"
                            :rate="offerPreview.rate"
                            :ref-type="1"
                            :ref-id="offerPreview.id"

                            @reload="wasRated")

                .col-xs-12
                    OptiCatalogOfferHeader(
                        :selected="offerPreview.filters"
                        :is-skipped="!!(offerPreview.filters.questions.frames[4] && JSON.stringify(offerPreview.filters.questions.frames[4].options.map(o => o.key)) === JSON.stringify(['sunglasses']))")

                    OptiCatalogOfferHeaderProducts(
                        :products="offerPreview.products"
                        :department-id="departmentId"

                        @reload="wasRated")

        template(#footer)
            .footer-buttons(v-if="!loading")
                OptiButton(:title="'patient.offer.TITLEOFFER'|translate"
                    @click.native="$state.go('app.patient.detail', {id: offerPreview.patient_id, '#': 'offers', offerId: offerPreview.id})") {{'patient.offer.TITLEOFFER'|translate}}
                    template(#icon)
                        i.fa.fa-briefcase

                OptiCatalogOfferBtnMail(
                    :offer="offerPreview"
                    :patient="offerPreview.patient")

                OptiCatalogOfferBtnPortraitsEdit(
                    :offer="offerPreview"

                    @reload="load")

                OptiButtonHistory(data="offer" :id="offerPreview.id")

                OptiButton(@click.native="$emit('closed', rated)") {{'default.OK'|translate}}
                    template(#icon)
                        i.fa.fa-check

            OptiDimmer(:active="loading")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiPatient from '@/js/app/vue/components/Patients/OptiPatient'
    import OptiCatalogOfferHeader from '@/js/app/vue/components/Catalog/Offer/Header/OptiCatalogOfferHeader'
    import OptiCatalogOfferFavorites
        from '@/js/app/vue/components/Catalog/Offer/Favorites/OptiCatalogOfferFavorites'
    import OptiRate from '@/js/app/vue/components/Rate/OptiRate'
    import OptiCatalogOfferHeaderProducts
        from '@/js/app/vue/components/Catalog/Offer/Header/OptiCatalogOfferHeaderProducts'
    import OptiProductInfo from '@/js/app/vue/components/Warehouse/Products/OptiProductInfo'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiCatalogOfferBtnMail from '@/js/app/vue/components/Catalog/Offer/OptiCatalogOfferBtnMail'
    import OptiCatalogOfferBtnPortraitsEdit
        from '@/js/app/vue/components/Catalog/Offer/OptiCatalogOfferBtnPortraitsEdit'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiButtonHistory from '@/js/app/vue/components/Button/OptiButtonHistory'

    export default {
        name: 'OptiCatalogOfferPreviewDialog',
        components: {
            OptiDialog,
            OptiPatient,
            OptiProductInfo,
            OptiCatalogOfferHeader,
            OptiCatalogOfferHeaderProducts,
            OptiRate,
            OptiCatalogOfferFavorites,
            OptiButton,
            OptiCatalogOfferBtnMail,
            OptiCatalogOfferBtnPortraitsEdit,
            OptiButtonHistory,
            OptiDimmer
        },
        props: {
            id: {
                type: Number,
                required: true
            },
            departmentId: {
                type: Number,
                required: true
            },
        },
        data() {
            return {
                offerPreview: [],

                rated: false,
                loading: true
            }
        },
        mounted() {
            this.load()
        },
        methods: {
            load() {
                this.loading = true

                API.get('patient/offer/preview/' + this.id).then(res => {
                    this.offerPreview = res.data
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('patient.offer.notify.error.LOAD')
                })
            },
            wasRated() {
                this.rated = true

                this.load()
            }
        }
    }
</script>

<style lang="less" scoped>
    .pb {
        padding-bottom: 10px;
    }

    .footer-buttons {
        display: flex;
        justify-content: space-between;
    }

    .space-around {
        display: flex;
        justify-content: space-around;
    }
</style>
